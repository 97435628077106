<template>
  <div>
    <Header :customstyle="{ background: '#0D1912' }">
			<template slot="title">操作指南</template>
		</Header>
    <div class="detail">
      <div class="content" >
        <div class="title">{{title}}</div>
        <div class="describle" v-html="detailcontent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getGuideDetail} from '@/request/api'
import Header from '../../components/header.vue'
export default {
  name: 'Operationdetail',
 components: { Header },
  data() {
    return {
      detailID: 0,
      title:'',
      detailcontent:''
    };
  },

  mounted() {
    
  },
  created () {

  },
  activated () {
    this.detailID = this.$route.query.id
    console.log(this.detailID)
    this.getDetail()
  },
  methods: {
    getDetail (){
      getGuideDetail({id:this.detailID}).then((res)=>{
        this.title = res.data.title
        this.detailcontent = res.data.content
        console.log(this.title)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.detail {
  padding: 10px 32px;
  background: #0D1912;
  min-height: 100vh;
  .content {
    padding: 24px;
    width: 100%;
    border-radius: 24px;
    background: rgba(32,40,35,1);
    // color: rgba(139,166,149,1);
    //     font-size: 28px;
    //     font-weight: 400;
    .title {
      opacity: 1;
      color: rgba(214,255,227,1);
      font-size: 32px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: left;
      line-height: 44px;
    }
    .describle{
        color: rgba(139,166,149,1);
        font-size: 28px;
        font-weight: 400;
    }
  }
}
</style>